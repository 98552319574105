// footer.js
import React from 'react';
import {Link} from 'react-router-dom';
import fbIcon from '../img/fb.svg';
import instaIcon from '../img/insta.svg';
import twitterIcon from '../img/twitter.svg';
import logo from '../img/logo.svg';
import Head from './head';

class Footer extends React.Component {


	render() {
    const footerOptions = [
      { title: "Reserve", url: process.env.REACT_APP_HOME_DOMAIN_URL + "/reserve" },
      { title: "Locations", url: process.env.REACT_APP_HOME_DOMAIN_URL + "/locations" },
      { title: "Elite Bus", url: process.env.REACT_APP_HOME_DOMAIN_URL + "/elite-bus-service" },
      { title: "Rewards", url: process.env.REACT_APP_HOME_DOMAIN_URL + "/rewards" },
      { title: "FAQs", url: process.env.REACT_APP_HOME_DOMAIN_URL + "/faqs" }
    ];

		return (
      <footer>
        { process.env.REACT_APP_ENV=== "production"  && 
        <Head>
            <script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript"></script>
            <script type="text/javascript">
                {`ttd_dom_ready( function() {
                    if (typeof TTDUniversalPixelApi === 'function') {
                        var universalPixelApi = new TTDUniversalPixelApi();
                        universalPixelApi.init("mf6ndaf", ["883xg3o"], "https://insight.adsrvr.org/track/up");
                    }
                });`}
            </script>
        </Head>}
      <div className={"div"}>
      <div className="container">
        <div className={"layout"}>
            <div className={"section1"}>
                <div className={"logo_container"}>
                  <a href={`${process.env.REACT_APP_HOME_DOMAIN_URL}`}>
                    <img
                        loading="lazy"
                        src={logo}
                        height={100}
                        width={100}

                        alt="logo"
                        className={"logo"}
                    />
                    </a>
                    <p>Copyright © 2023 TripperBus.</p>
                </div>
                <div className={"gridContainer"}>
                    {footerOptions.map((option, index) =>{
                          return option.inappRoute ? <Link className={"navigational_links"} to={option.url}>{option.title}</Link> :
                            <a href={option.url} key={index} className={"navigational_links"}>
                                {option.title}
                            </a>

                    })}
                </div>
                <div className={"contactus"}>
                    <a href="tel:+1 (718) 834-9214">+1 (718) 834-9214</a>
                    <p>
                    <a
                        href="mailto:info@tripperbus.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        info@tripperbus.com
                    </a>
                    </p>
                    <div className={"socialIconSection"}>
                      <a href="https://www.facebook.com/TripperBus">
                        <img
                        loading="lazy"
                        src={fbIcon}
                        height={100}
                        width={100}
                        alt="facebook"
                        className={"socialIcon"}
                        />
                      </a>
                      <a href="https://twitter.com/tripperbus">
                        <img
                        loading="lazy"
                        src={twitterIcon}
                        height={100}
                        width={100}
                        alt="twitter"
                        className={"twitterIcon"}
                        />
                      </a>
                      <a href="https://www.instagram.com/tripperbus">
                        <img
                        loading="lazy"
                        src={instaIcon}
                        height={100}
                        width={100}
                        alt="instagram"
                        className={"socialIcon"}
                        />
                      </a>
                    </div>
                </div>
            </div>
          <div className={"divider"}/>
          <div className={"section2"}>
            <p >
              Operated by Gunther Charters, inc. USDOT 787086
            </p>
            <p>All rights reserved</p>
            <a href={`${process.env.REACT_APP_HOME_DOMAIN_URL + "/terms-and-conditions"}`}>Terms & Conditions</a>
            <a href={`${process.env.REACT_APP_HOME_DOMAIN_URL + "/privacy-policy"}`}>Privacy</a>
          </div>

          <div className={"divider"}/>
          <div className='disclaimer'>
            <p 
              dangerouslySetInnerHTML={{ __html: this.props.disclaimer }}
            />
          </div>

        </div>
        </div>
      </div>
      </footer>
		)
	}
}

export default Footer;
