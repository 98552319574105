// home.js
import React from 'react';
import ReservationStore from '../../stores/ReservationStore';
import Reservation from './reservation';
import Cancelled from './cancelled';
import Search from './search';
import UserStore from '../../stores/UserStore';
import Head from '../head';

class EditTrip extends React.Component {
  constructor(props) {
    super(props);
    this.onReservationChange = this.onReservationChange.bind(this);
    this.state = {
      reservation: null,
      currentStep: 1,
      refund: 0,
      email: ''
    };
  }

  clearReservation(email){
    this.setState({reservation: null, email: email});
  }

  setRefund(val) {
    this.setState({refund: val, currentStep: 3});
  }

  onReservationChange(){
    let result = ReservationStore.getReservation();
    if(result) {
      this.setState({reservation: result, currentStep: 2});
    }
  }

  componentDidMount() {
    ReservationStore.addReservationChangeListener(this.onReservationChange);
  }

  componentWillUnmount() {
    ReservationStore.removeReservationChangeListener(this.onReservationChange);
  }

  render() {
  	return (
      <div>
        <Head title="Edit Trip - Tripper Bus" />

        <header className="text-center">
        </header>
        {/* <section id="travel-form-region" className="travel-form">
          <div className="container">
            <div className="row mlr-0">
              <div className="card travel-form-wrapper">
                <div className="content content-full-width">
                  <div className="container-fluid tabs-wrapper p-0">
                    <div className="tabContent">
                      <div className="travel-form-title">Edit/Cancel My Trip</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {this.state.currentStep === 1 && <Search
          clearReservation = {this.clearReservation.bind(this)}
          location = {this.props.location}
        />}

        {this.state.currentStep === 2 && <Reservation
          reservation = {this.state.reservation}
          clearReservation = {this.clearReservation.bind(this)}
          setRefund = {this.setRefund.bind(this)}
        />}

        {this.state.currentStep === 3 && <Cancelled email={this.state.email} refund={this.state.refund}/>}

      </div>
  	)
  }
}

export default EditTrip;
