import React, { Component } from 'react';
import routeIcon from '../../../img/route-icon.svg';
import arrowSwitchIcon from '../../../img/double-arrow-gray.svg';
import dashIcon from '../../../img/dashIcon.svg';
import seatIcon from '../../../img/seat-icon-gray.svg';
import './Itinerary.scss';
import moment from 'moment';

class Itinerary extends Component {

    formatDate(date) {
        var formatedDate = ''
        try{
            formatedDate =  date.format("ddd, MMM D, YYYY");
        }catch(e){
        }finally{
            return formatedDate
        }
    }

    render() {
        return (
            <div className="itinerary-card form-group clearfix tripper-card">
                <div className="row border-bottom">
                    <div className="column grid">
                        <p className='title'>Your Itinerary</p>
                        <div className='only-desktop'>
                        <span><img src={seatIcon} height={"16px"} width={"16px"} alt='seat' /> {this.props.data.seats} {this.props.data.seats == 1 ?"Seat":"Seats"}</span>
                            <span> | </span>
                            <span> {this.props.data.isRoundTripSelected ? 'Round Trip': 'One way'} </span>
                        </div>
                        <div className='only-mobile grid'>
                            <span>{this.props.data.isRoundTripSelected ? 'Round Trip': 'One way'}</span>
                            <span><img src={seatIcon} height={"16px"} width={"16px"} alt='seat' /> {this.props.data.seats} {this.props.data.seats == 1 ?"Seat":"Seats"}</span>
                        </div>
                    </div>
                    <div className="column end editTripBtn">
                        <button  onClick={()=>this.props.editTrip()}>Edit Trip</button>
                    </div>
                </div>
                <div className="row mobile-row">
                    <div className='locations'>
                        <div className='location'>
                            <p className='date'>{this.formatDate(this.props.data.departDate)}</p>
                            <div className='routes'>
                                <p>{this.props.data.departCity}</p>
                                <img src={routeIcon} width="11px" height="10px" alt='route' />
                                <p>{this.props.data.arriveCity}</p>
                            </div>
                            <div className='routes gap'>
                                <div className='time'>{moment(this.props.data.depart_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                                <div></div>
                                <div className='time'>{moment(this.props.data.arrive_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                        </div>
                        {
                            this.props.data.isRoundTripSelected === true && <img className='only-desktop' src={arrowSwitchIcon} width="24px" height="24px" alt='switch' />
                        }
                        {
                            this.props.data.isRoundTripSelected === true && <img className='only-mobile' src={dashIcon} width="24px" height="48px" alt='switch' />
                        }
                        {
                            this.props.data.isRoundTripSelected === true && <div className='location'>
                            <p className='date'>{this.formatDate(this.props.data.returnDate)}</p>
                            <div className='routes'>
                                <p>{this.props.data.returnDepartCity}</p>
                                <img src={routeIcon} width="11px" height="10px" alt='route' />
                                <p>{this.props.data.returnArriveCity}</p>
                            </div>
                            <div className='routes gap'>
                                <div className='time'>{moment(this.props.data.depart_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                                <div></div>
                                <div className='time'>{moment(this.props.data.arrive_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                        </div>
                        }
                    </div>
                    {/* <div className="column end">Route Details</div> */}
                </div>
            </div>
        );
    }
}

export default Itinerary;
