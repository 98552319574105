import React, { Component } from 'react';

import './Login.scss';
import Modal from '../../Modal/modal';

class Login extends Component {
    render() {
        return (
                <div>
                <div className="login-card-container">
                    <div className="login-card-content">
                        {/* <img src={Reactangle} height={200} width={150}/> */}
                    </div>
                    <div className="login-card-footer">
                        <div className='title'>
                            <p>Book Faster with a TripperBus Account</p>
                            <p>Sign in to TripperBus to save time and earn points every time you ride.</p>
                        </div>
                        <div className='login-button-container'>
                            <button data-toggle="modal" data-target="#login">Log in</button>
                            <button onClick={()=>this.props.onContinueAsGuest()}>Continue as Guest</button>
                        </div> 
                    </div>
                </div>
                <Modal id='login'>
                    {this.props.children}
                </Modal>

            </div>
        );
    }
}

export default Login;
