// UserActions.js
import Dispatcher from '../dispatcher/Dispatcher';
import { history as browserHistory } from '../helpers/history';

export function loginUser(token, pathname) {
  localStorage.setItem('token', token);
  Dispatcher.handleAction({
    type: 'LOGIN_USER',
    data: token
  });
  if (pathname && pathname !== '/') {
    browserHistory.push(pathname);
  }
  else {
    browserHistory.push('/');
  }
}

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}

export function redirectUser(token, pathname, query) {
  localStorage.setItem('token', token);
  Dispatcher.handleAction({
    type: 'LOGIN_USER',
    data: token
  });
  if(pathname === '/') {
    pathname = '';
  }
  if (query) {
    browserHistory.push(pathname + '?' + getQueryString(query));
  }
  else if(pathname) {
    browserHistory.push(pathname);
  }
  else {
    browserHistory.push('/');
  }
}

export function setUser(token) {
  localStorage.setItem('token', token);
  Dispatcher.handleAction({
    type: 'SET_USER',
    data: token
  });
}

export function setTopbar(data) {
  Dispatcher.handleAction({
    type: 'SET_TOPBAR',
    data: data
  });
}

export function setFooterDisclaimer(data) {
  Dispatcher.handleAction({
    type: 'SET_FOOTER_DISCLAIMER',
    data: data
  });
}

export function registerUser(token) {
  localStorage.setItem('token', token);
  Dispatcher.handleAction({
    type: 'REGISTER_USER',
    data: token
  });
  browserHistory.push('/');
}

export function logoutUser() {
  localStorage.removeItem('token');
  Dispatcher.handleAction({
    type: 'LOGOUT_USER'
  });
  browserHistory.push('/login');
}
