// home.js
import React from 'react';
import ReservationHistory from './reservationHistory';
import Overview from './overview';
import AccountDetails from './accountDetails';
import ChangePassword from './changePassword';
import Head from '../head';

class ManageAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1
    };
  }

  setCurrentStep(step) {
    this.setState({currentTab: step});
  }

  getPageTitle() {
    if(this.state.currentTab === 1) {
      return 'Account Overview';
    }
    else if(this.state.currentTab === 2) {
      return 'My Reservations';
    }
    else if(this.state.currentTab === 3) {
      return 'Account Details';
    }
    else if(this.state.currentTab === 4) {
      return 'Change Password';
    }
  }

  render() {
  	return (
      <div className="container-fluid manage-account">
        <Head title="Dashboard - Tripper Bus" />

      <div className="container">
        <div className="row clearfix">
          <div className="col-md-3 left-navbar">
            <ul className="nav flex-column">
              <li className={'nav-item ' + (this.state.currentTab === 1 ? 'active' : '')} >
                <a className="nav-link" href="javascript:void(0)" onClick={this.setCurrentStep.bind(this, 1)}>Account Overview</a>
              </li>
              <li className={'nav-item ' + (this.state.currentTab === 2 ? 'active' : '')}>
                <a className="nav-link" href="javascript:void(0)" onClick={this.setCurrentStep.bind(this, 2)}>My Reservations</a>
              </li>
              <li className={'nav-item ' + (this.state.currentTab === 3 ? 'active' : '')}>
                <a className="nav-link" href="javascript:void(0)" onClick={this.setCurrentStep.bind(this, 3)}>Account Details</a>
              </li>
              <li className={'nav-item ' + (this.state.currentTab === 4 ? 'active' : '')}>
                <a className="nav-link" href="javascript:void(0)" onClick={this.setCurrentStep.bind(this, 4)}>Change Password</a>
              </li>
            </ul>
          </div>
          <div className="col-md-9 manage-account-content">
            <div className="page-title">{this.getPageTitle()}</div>
            {this.state.currentTab === 1 && <Overview setCurrentStep = {this.setCurrentStep.bind(this)}/>}
            {this.state.currentTab === 2 && <ReservationHistory/>}
            {this.state.currentTab === 3 && <AccountDetails/>}
            {this.state.currentTab === 4 && <ChangePassword/>}
          </div>
        </div>
        </div>
      </div>
  	)
  }
}

export default ManageAccount;