import React from 'react';
import Auth from '../../services/auth';

export class Captcha extends React.Component {


  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onLoadCallback&render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.onLoadCallback = () => {
      window.grecaptcha.render('g-recaptcha-container', {
        sitekey: process.env.REACT_APP_CAPCTHA_SITE_KEY,
        callback: (data)=>{
          Auth.verifyCaptcha(data)
            .then((data)=> this.props.setCaptchaSuccess(data.success))
            .catch((err)=>{
              window.grecaptcha.reset();
              this.props.setCaptchaSuccess(false)
            });},
            'expired-callback':  () => {this.props.setCaptchaSuccess(false);},
          type: 'image'
      });
    };
  }

  componentWillUnmount() {
    const script = document.querySelector('script[src="https://www.google.com/recaptcha/api.js?onload=onLoadCallback&render=explicit"]');
    document.body.removeChild(script);
    delete window.onLoadCallback;
  }

  render() {
    return <div style={{marginBottom: '1rem'}} id="g-recaptcha-container"></div>;
  }


}
