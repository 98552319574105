// application.js
import request from 'superagent';
import { setSchedules, setReturnSchedules } from '../actions/ScheduleActions.js';
import config from '../config';

class ScheduleService {
  get(query, isReturn) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'schedule/search')
      .send(query)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          if(isReturn) {
            resolve(setReturnSchedules(response.body));  
          }
          else {
            resolve(setSchedules(response.body));
          }
        }
        else if(error){
          reject('Error: '+JSON.stringify(error));
        }
        else{
          reject(response.body);
        }
      });
    })
  }
}

export default new ScheduleService();