// UserActions.js
import Dispatcher from '../dispatcher/Dispatcher';

export function setReservation(reservation) {
  Dispatcher.handleAction({
    type: 'SET_RESERVATION',
    data: reservation
  });
}

export function setHistory(history) {
  Dispatcher.handleAction({
    type: 'SET_HISTORY',
    data: history
  });
}

export function setUpcoming(upcoming) {
  Dispatcher.handleAction({
    type: 'SET_UPCOMING',
    data: upcoming
  });
}