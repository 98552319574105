// home.js
import React from "react";
import ScheduleService from "../../services/schedule";
import ScheduleStore from "../../stores/ScheduleStore";
import moment from "moment";
import _ from "lodash";
import Trips from "./trips";
import TripFilters from "./tripFilters";
import ConfirmTrip from "./confirmTrip";
import config from "../../config";
import CollapsedTripFilters from "./collapsedTripFilters";
import showToast from "../../helpers/toast";
import ReservationStore from "../../stores/ReservationStore";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.onSchedulesChange = this.onSchedulesChange.bind(this);
    this.onReturnSchedulesChange = this.onReturnSchedulesChange.bind(this);
    this.state = {
      loading: false,
      isTripFilterCollapsed: false,
      returnLoading: false,
      departCity: "",
      arriveCity: "",
      returnDepartCity: "",
      returnArriveCity: "",
      departDate: moment(),
      returnDate: null,
      itemsInSlider: 5,
      seats: "1",
      currentStep: 2,
      isSearchClicked: false,
      today: moment().startOf("day"),
      trips: {
        departingTrips: [],
        returningTrips: [],
      },
      futureDeparts: [],
      futureReturns: [],
      departTrip: null,
      returnTrip: null,
      cities: config.cities,
      arriveCities: config.cities,
      returnArriveCities: [],
      durationBetweenCities: config.durationBetweenCities,
      depart_stop: null,
      depart_stop1: null,
      arrive_stop: null,
      arrive_stop1: null,
      wrongTime: false,
      isRoundTripSelected: false,
    };
    this.days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    console.log("HERE", ReservationStore.getReservation());
  }

  setTripFilterCollapsed(value) {
    this.setState({ isTripFilterCollapsed: value });
  }

  selectTripType(data) {
    let departDate = this.state.departDate.clone();

    if (!data.isRoundTripSelected) {
      this.removeReturn();
    } else {
      this.setState(
        {
          returnDepartCity: this.state.arriveCity,
          returnArriveCity: this.state.departCity,
          returnDate: departDate.add(1, "days"),
          isRoundTripSelected: data.isRoundTripSelected,
        },
        () => {
          this.createDepartFutureArray(this.state.departDate);
          this.createReturnFutureArray(this.state.returnDate);
          if (
            this.state.returnDepartCity &&
            this.state.returnArriveCity &&
            this.state.departDate &&
            this.state.returnDate &&
            this.state.seats
          ) {
            //this.getSchedules();
          }
        }
      );
    }
  }

  handleUserInput(e) {
    let targetName = e.target.name;

    if (targetName === "departCity") {
      this.setState({
        departCity: e.target.value,
        arriveCity: "",
        returnDepartCity: "",
        returnArriveCity: "",
      });

      let arriveCities = [];
      let returnArriveCities = [];
      if (e.target.value === "new york" || e.target.value === "brooklyn") {
        config.cities.map((item) => {
          if (!["new york", "brooklyn"].includes(item.value)) {
            arriveCities.push(item);
          } else {
            returnArriveCities.push(item);
          }
          return item;
        });
      } else {
        config.cities.map((item) => {
          if (item.value === "new york" || item.value == "brooklyn") {
            arriveCities.push(item);
          } else {
            returnArriveCities.push(item);
          }
          return item;
        });
      }
      this.setState({ arriveCities: arriveCities, returnArriveCities });
      return;
    } else if (targetName === "arriveCity") {
      if (this.state.departCity && e.target.value === this.state.departCity) {
        return;
      }
      if (this.state.isRoundTripSelected) {
        this.setState({
          returnDepartCity: e.target.value,
          returnArriveCity: this.state.departCity,
        });
      }
    } else if (targetName === "returnDepartCity") {
      this.setState({
        returnDepartCity: e.target.value,
        returnArriveCity: this.state.returnArriveCity,
      });
    }
    this.setState({
      trips: {
        departingTrips: [],
        returningTrips: [],
      },
      isSearchClicked: false,
    });

    this.setState({ [targetName]: e.target.value }, function () {
      if (
        this.state.isRoundTripSelected &&
        this.state.departCity &&
        this.state.arriveCity &&
        this.state.departDate &&
        this.state.returnDate &&
        this.state.seats &&
        this.state.returnArriveCity &&
        this.state.returnDepartCity
      ) {
        this.createDepartFutureArray(this.state.departDate);
        this.createReturnFutureArray(this.state.returnDate);
        // //this.getSchedules();
      } else if (
        !this.state.isRoundTripSelected &&
        this.state.departCity &&
        this.state.arriveCity &&
        this.state.departDate &&
        this.state.seats
      ) {
        this.createDepartFutureArray(this.state.departDate);
        // //this.getSchedules();
      }
    });
  }

  getRequiredStop(stops, city) {
    const data = _.find(stops, (stop) => {
      return stop.stop.city === city;
    });

    return data;
  }

  getCityCodeFromCityValue(cityValue) {
    return _.find(this.state.cities, (c) => {
      return c.value === cityValue;
    });
  }

  scrollToSection(element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  selectDepartTrip(bus, e, el = null) {
    e.preventDefault();
    if (bus.remainingSeats < this.state.seats) {
      return;
    }
    if (
      this.state.departTrip &&
      this.state.departTrip.schedule_bus === bus.schedule_bus
    ) {
      this.setState({ departTrip: null, returnTrip: null });
      return;
    } else {
      this.setState({
        departTrip: bus,
        depart_stop: this.getRequiredStop(bus.stops, this.state.departCity),
        depart_time: this.getRequiredStop(bus.stops, this.state.departCity)
          .time,
        arrive_stop: this.getRequiredStop(bus.stops, this.state.arriveCity),
        arrive_time: this.getRequiredStop(bus.stops, this.state.arriveCity)
          .time,
      });
      if (this.state.isRoundTripSelected) {
        if (el) {
          this.scrollToSection(el);
        }
        return;
      } else {
        this.getTickets();
      }
    }
  }

  selectReturnTrip(bus, e) {
    e.preventDefault();
    if (bus.remainingSeats < this.state.seats) {
      return;
    }
    if (
      this.state.returnTrip &&
      this.state.returnTrip.schedule_bus === bus.schedule_bus
    ) {
      this.setState({ returnTrip: null });
      return;
    } else {
      this.setState(
        {
          returnTrip: bus,
          depart_stop1: this.getRequiredStop(
            bus.stops,
            this.state.returnDepartCity
          ),
          depart_time1: this.getRequiredStop(
            bus.stops,
            this.state.returnDepartCity
          ).time,
          arrive_stop1: this.getRequiredStop(
            bus.stops,
            this.state.returnArriveCity
          ),
          arrive_time1: this.getRequiredStop(
            bus.stops,
            this.state.returnArriveCity
          ).time,
        },
        () => {
          this.getTickets();
        }
      );
    }
  }

  sortByTime(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].stops.length === 3) {
        if (data[i].stops[0].type === "DO") {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0],
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if (data[i].stops[0].type === "DO") {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0],
          ];
        }
      } else if (data[i].stops.length === 2) {
        if (data[i].stops[0].type === "DO") {
          data[i].stops = [data[i].stops[1], data[i].stops[0]];
        }
      }
    }
    return _.sortBy(data, (item) => moment(item.stops[0].time, "HH:mm:ss"));
  }

  filterUpcoming(data) {
    return _.filter(data, (item) => {
      const requiredDepartStopTime = this.getRequiredStop(
        item.stops,
        this.state.departCity
      );
      const requiredArriveStopTime = this.getRequiredStop(
        item.stops,
        this.state.arriveCity
      );
      if (!requiredDepartStopTime || !requiredArriveStopTime) return false;
      return (
        moment(
          moment(this.state.departDate).format("YYYY-MM-DD") +
            " " +
            requiredDepartStopTime.time,
          "YYYY-MM-DD HH:mm:ss"
        ).diff(moment(), "minutes") > 5
      );
    });
  }

  filterUpcomingReturn(data) {
    return _.filter(data, (item) => {
      const requiredDepartStopTime = this.getRequiredStop(
        item.stops,
        this.state.returnDepartCity
      );
      const requiredArriveStopTime = this.getRequiredStop(
        item.stops,
        this.state.returnArriveCity
      );
      if (!requiredDepartStopTime || !requiredArriveStopTime) return false;
      return (
        moment(
          moment(this.state.returnDate).format("YYYY-MM-DD") +
            " " +
            requiredArriveStopTime.time,
          "YYYY-MM-DD HH:mm:ss"
        ).diff(moment(), "minutes") > 5
      );
    });
  }

  onSchedulesChange() {
    let results = ScheduleStore.getSchedules();

    let trips = {
      departingTrips: [],
      returningTrips: [],
    };
    if (results.length > 0) {
      trips.departingTrips = this.sortByTime(this.filterUpcoming(results));
    }
    if (this.state.returnDate) {
      let results1 = ScheduleStore.getReturnSchedules();
      if (results1.length > 0) {
        trips.returningTrips = this.sortByTime(
          this.filterUpcomingReturn(results1)
        );
      }
    }
    this.setState({ trips: trips, loading: false });
    if (this.state.currentStep !== 3) {
      this.setState({ departTrip: null, returnTrip: null });
    }
  }

  onReturnSchedulesChange() {
    // let results = ScheduleStore.getSchedules();
    let trips = {
      departingTrips: this.state.trips.departingTrips,
      returningTrips: [],
    };
    // if(results.length > 0) {
    //   trips.departingTrips = this.sortByTime(this.filterUpcoming(results));
    // }
    if (this.state.returnDate) {
      let results1 = ScheduleStore.getReturnSchedules();
      if (results1.length > 0) {
        trips.returningTrips = this.sortByTime(
          this.filterUpcomingReturn(results1)
        );
      }
    }
    this.setState({ trips: trips, returnLoading: false });
    if (this.state.currentStep !== 3) {
      this.setState({ returnTrip: null });
    }
  }

  editTrip() {
    this.setState({ currentStep: 2, departTrip: null, returnTrip: null });
  }

  getSchedules(e) {
    e && e.preventDefault();
    if (this.state.departCity === this.state.arriveCity) {
      return;
    }
    // if(this.state.departCity === 'arlington' || this.state.departCity === 'bethesda') {
    //   if(this.state.arriveCity !== 'new york') {
    //     return;
    //   }
    // }
    this.setState({ isSearchClicked: true });
    this.setTripFilterCollapsed(true);
    this.setState({ wrongTime: false });
    this.setState({
      trips: {
        departingTrips: [],
        returningTrips: [],
      },
      loading: true,
    });
    if (this.state.returnDate) {
      this.setState({ returnLoading: true });
    }
    let criteria = {
      departCity: this.state.departCity,
      arriveCity: this.state.arriveCity,
      departDate: moment(this.state.departDate).format("YYYY-MM-DD"),
      seats: parseInt(this.state.seats),
    };
    ScheduleService.get(criteria)
      .then(() => {})
      .catch((err) => {
        this.setState({ loading: false });
      });
    if (this.state.returnDate) {
      let criteriaReturn = {
        departCity: this.state.returnDepartCity,
        arriveCity: this.state.returnArriveCity,
        departDate: moment(this.state.returnDate).format("YYYY-MM-DD"),
        seats: parseInt(this.state.seats),
      };
      ScheduleService.get(criteriaReturn, true)
        .then(() => {})
        .catch((err) => {
          this.setState({ returnLoading: false });
        });
    }
  }

  getReturnSchedules() {
    if (this.state.returnDate) {
      this.setState({
        trips: {
          ...this.state.trips,
          returningTrips: [],
        },
        returnLoading: true,
      });
      let criteriaReturn = {
        departCity: this.state.returnDepartCity,
        arriveCity: this.state.returnArriveCity,
        departDate: moment(this.state.returnDate).format("YYYY-MM-DD"),
        seats: parseInt(this.state.seats),
      };
      ScheduleService.get(criteriaReturn, true)
        .then(() => {})
        .catch((err) => {
          this.setState({ returnLoading: false });
        });
    }
  }

  createDepartFutureArray(date, addSub) {
    let dayNumber = new Date(date).getDay();
    let departDay = this.days[dayNumber];

    if (this.state.futureDeparts.length > 0 && !date) {
      let futureDeparts = this.state.futureDeparts;
      for (let i = 0; i < futureDeparts.length; i++) {
        if (addSub === "add") {
          futureDeparts[i].date = moment(futureDeparts[i].date, "M/D/YY")
            .add(1, "day")
            .format("M/D/YY");
          futureDeparts[i].day =
            this.days[
              new Date(moment(futureDeparts[i].date, "M/D/YY")).getDay()
            ];
        } else {
          futureDeparts[i].date = moment(futureDeparts[i].date, "M/D/YY")
            .subtract(1, "day")
            .format("M/D/YY");
          futureDeparts[i].day =
            this.days[
              new Date(moment(futureDeparts[i].date, "M/D/YY")).getDay()
            ];
        }
      }
      this.setState({ futureDeparts: futureDeparts });
    } else if (this.state.itemsInSlider === 5) {
      let futureDeparts = [
        {
          date: moment(date).subtract(2, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(2, "day")).getDay()],
        },
        {
          date: moment(date).subtract(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(1, "day")).getDay()],
        },
        {
          date: moment(date).format("M/D/YY"),
          day: departDay,
        },
        {
          date: moment(date).add(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(1, "day")).getDay()],
        },
        {
          date: moment(date).add(2, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(2, "day")).getDay()],
        },
      ];
      this.setState({ futureDeparts: futureDeparts });
    } else {
      let futureDeparts = [
        {
          date: moment(date).subtract(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(1, "day")).getDay()],
        },
        {
          date: moment(date).format("M/D/YY"),
          day: departDay,
        },
        {
          date: moment(date).add(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(1, "day")).getDay()],
        },
      ];
      this.setState({ futureDeparts: futureDeparts });
    }
  }

  createReturnFutureArray(date, addSub) {
    let dayNumber = new Date(date).getDay();
    let returnDay = this.days[dayNumber];
    if (this.state.futureReturns.length > 0 && !date) {
      let futureReturns = this.state.futureReturns;
      for (let i = 0; i < futureReturns.length; i++) {
        if (addSub === "add") {
          futureReturns[i].date = moment(futureReturns[i].date, "M/D/YY")
            .add(1, "day")
            .format("M/D/YY");
          futureReturns[i].day =
            this.days[
              new Date(moment(futureReturns[i].date, "M/D/YY")).getDay()
            ];
        } else {
          futureReturns[i].date = moment(futureReturns[i].date, "M/D/YY")
            .subtract(1, "day")
            .format("M/D/YY");
          futureReturns[i].day =
            this.days[
              new Date(moment(futureReturns[i].date, "M/D/YY")).getDay()
            ];
        }
      }
      this.setState({ futureReturns: futureReturns });
    } else if (this.state.itemsInSlider === 5) {
      let futureReturns = [
        {
          date: moment(date).subtract(2, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(2, "day")).getDay()],
        },
        {
          date: moment(date).subtract(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(1, "day")).getDay()],
        },
        {
          date: moment(date).format("M/D/YY"),
          day: returnDay,
        },
        {
          date: moment(date).add(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(1, "day")).getDay()],
        },
        {
          date: moment(date).add(2, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(2, "day")).getDay()],
        },
      ];
      this.setState({ futureReturns: futureReturns });
    } else {
      let futureReturns = [
        {
          date: moment(date).subtract(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).subtract(1, "day")).getDay()],
        },
        {
          date: moment(date).format("M/D/YY"),
          day: returnDay,
        },
        {
          date: moment(date).add(1, "day").format("M/D/YY"),
          day: this.days[new Date(moment(date).add(1, "day")).getDay()],
        },
      ];
      this.setState({ futureReturns: futureReturns });
    }
  }

  handleDepartDateChange(value, e) {
    e.preventDefault();
    if (value.startOf("day").diff(this.state.today, "days") < 0) {
      return;
    }
    if (this.state.returnDate) {
      if (this.state.returnDate.diff(value.startOf("day"), "days") < 0) {
        this.setState(
          { departDate: value.startOf("day"), returnDate: null },
          function () {
            this.createDepartFutureArray(this.state.departDate);
            if (
              this.state.departCity &&
              this.state.arriveCity &&
              this.state.departDate &&
              this.state.seats
            ) {
              //this.getSchedules();
              this.hideTrips();
            }
          }
        );
      } else {
        this.setState({ departDate: value.startOf("day") }, function () {
          this.createDepartFutureArray(this.state.departDate);
          if (
            this.state.departCity &&
            this.state.arriveCity &&
            this.state.departDate &&
            this.state.returnDate &&
            this.state.seats
          ) {
            // //this.getSchedules();
            this.hideTrips();
          }
        });
      }
    } else {
      this.setState({ departDate: value.startOf("day") }, function () {
        this.createDepartFutureArray(this.state.departDate);
        if (
          this.state.departCity &&
          this.state.arriveCity &&
          this.state.departDate &&
          this.state.seats
        ) {
          //this.getSchedules();
          this.hideTrips();
        }
      });
    }
  }

  handleReturnDateChange(value, e) {
    e.preventDefault();
    if (value.startOf("day").diff(this.state.departDate, "days") < 0) {
      return;
    }
    this.setState({ returnDate: value.startOf("day") }, function () {
      this.createDepartFutureArray(this.state.departDate);
      this.createReturnFutureArray(this.state.returnDate);
      if (
        this.state.returnDepartCity &&
        this.state.returnArriveCity &&
        this.state.departDate &&
        this.state.returnDate &&
        this.state.seats
      ) {
        //this.getSchedules();
        this.hideTrips();
      }
    });
  }

  removeReturn() {
    this.setState({
      returnDate: null,
      futureReturns: [],
      returnTrip: null,
      depart_stop1: null,
      depart_time1: null,
      arrive_stop1: null,
      arrive_time1: null,
      returnArriveCity: "",
      returnDepartCity: "",
      isRoundTripSelected: false,
    });
  }

  checkFutureDepart(date) {
    if (this.checkPastDate(date)) {
      return;
    }
    this.setState(
      {
        departDate: moment(date, "M/D/YY"),
        returnDate: moment(date, "M/D/YY"),
      },
      () => {
        this.getSchedules();
      }
    );
  }

  checkFutureReturns(date) {
    if (this.checkPastDate(date) || this.checkPastReturnDate(date)) {
      return;
    }
    this.setState({ returnDate: moment(date, "M/D/YY") }, () => {
      this.getReturnSchedules();
    });
  }

  getStopLabel(stop) {
    return _.find(this.state.cities, { value: stop }).label;
  }

  getShortLabel(stop) {
    if (stop) {
      return _.find(this.state.cities, { value: stop }).shortLabel;
    }
  }

  getStopLabelWithZip(stop) {
    let label = _.find(this.state.cities, { value: stop });
    return label.label;
  }

  getStopMetro(stop) {
    return _.find(this.state.cities, { value: stop }).metro;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, { value: stop }).address;
  }

  getDropOffStop(stop) {
    return _.find(this.state.cities, { value: stop }).dropoffAddress;
  }

  getTime(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  checkActiveDay(day, currentDay) {
    return new Date(day).getDate() === new Date(currentDay).getDate();
  }

  onReservationChange() {
    console.log("RES CHANGE");
  }

  componentDidMount() {
    window.$("html, body").animate({ scrollTop: 0 }, "fast");
    ScheduleStore.addChangeListener(this.onSchedulesChange);
    ScheduleStore.addReturnListener(this.onReturnSchedulesChange);

    ReservationStore.addReservationChangeListener(this.onReservationChange);

    let width = window.$(window).width();
    let arriveCities = [];
    let returnArriveCities = [];

    if (width <= 1023) {
      this.setState({ itemsInSlider: 3 });
    } else {
      this.setState({ itemsInSlider: 5 });
    }
    let obj = {};
    if (this.props.location.query.departCity) {
      obj.departCity = this.props.location.query.departCity.replace("+", " ");
      if (obj.departCity === "new york" || obj.departCity === "brooklyn") {
        config.cities.map((item) => {
          if (!["new york", "brooklyn"].includes(item.value)) {
            arriveCities.push(item);
          } else {
            returnArriveCities.push(item);
          }
          return item;
        });
      } else {
        config.cities.map((item) => {
          if (item.value === "new york" || item.value == "brooklyn") {
            arriveCities.push(item);
          } else {
            returnArriveCities.push(item);
          }
          return item;
        });
      }

      this.setState({ arriveCities, returnArriveCities });
    }
    if (this.props.location.query.arriveCity) {
      obj.arriveCity = this.props.location.query.arriveCity.replace("+", " ");
    }
    if (this.props.location.query.departDate) {
      obj.departDate = moment(
        this.props.location.query.departDate,
        "YYYY-MM-DD"
      );
      if (this.props.location.query.returnDate) {
        obj.returnDate = moment(
          this.props.location.query.returnDate,
          "YYYY-MM-DD"
        );
      }
    }
    if (this.props.location.query.seats) {
      obj.seats = parseInt(this.props.location.query.seats);
    }
    if (this.props.location.query.seats) {
      obj.seats = parseInt(this.props.location.query.seats);
    }
    if (this.props.location.query.tripType) {
      obj.isRoundTripSelected =
        this.props.location.query.tripType === "roundtrip";
      if (this.props.location.query.returnDepartCity) {
        obj.returnDepartCity =
          this.props.location.query.returnDepartCity.replace("+", " ");
      }
      if (this.props.location.query.returnArriveCity) {
        obj.returnArriveCity =
          this.props.location.query.returnArriveCity.replace("+", " ");
      }
      if (this.props.location.query.returnDate) {
        obj.returnDate = moment(
          this.props.location.query.returnDate,
          "YYYY-MM-DD"
        );
      }
    }

    if (Object.keys(obj).length > 0) {
      this.setState(obj, () => {
        if (
          this.state.departCity &&
          this.state.arriveCity &&
          this.state.departDate &&
          this.state.returnDate &&
          this.state.seats &&
          this.state.returnArriveCity &&
          this.state.returnDepartCity
        ) {
          this.createDepartFutureArray(this.state.departDate);
          this.createReturnFutureArray(this.state.returnDate);
          this.getSchedules();
        } else if (
          this.state.departCity &&
          this.state.arriveCity &&
          this.state.departDate &&
          this.state.seats
        ) {
          this.createDepartFutureArray(this.state.departDate);
          this.getSchedules();
        }
      });
    }
    window.$(window).resize(() => {
      let width = window.$(window).width();

      if (width <= 1023) {
        if (this.state.itemsInSlider !== 3) {
          this.setState({ itemsInSlider: 3 }, () => {
            if (this.state.futureDeparts.length > 0) {
              this.createDepartFutureArray(
                moment(this.state.futureDeparts[2].date, "M/D/YY")
              );
            }
            if (this.state.futureReturns.length > 0) {
              this.createReturnFutureArray(
                moment(this.state.futureReturns[2].date, "M/D/YY")
              );
            }
          });
        }
      } else {
        if (this.state.itemsInSlider !== 5) {
          this.setState({ itemsInSlider: 5 }, () => {
            if (this.state.futureDeparts.length > 0) {
              this.createDepartFutureArray(
                moment(this.state.futureDeparts[1].date, "M/D/YY")
              );
            }
            if (this.state.futureReturns.length > 0) {
              this.createReturnFutureArray(
                moment(this.state.futureReturns[1].date, "M/D/YY")
              );
            }
          });
        }
      }
    });

    this.setState({ returnTrip: null });
  }

  componentWillUnmount() {
    ScheduleStore.removeChangeListener(this.onSchedulesChange);
    ScheduleStore.removeReturnListener(this.onReturnSchedulesChange);
  }

  checkActiveStep(step) {
    return this.state.currentStep === step;
  }

  getTickets() {
    this.setState({ wrongTime: false });
    if (this.state.returnTrip) {
      if (
        this.state.returnDate
          .startOf("day")
          .diff(this.state.departDate.startOf("day"), "days") < 0
      ) {
        this.setState({ wrongTime: "Return must come after departure date." });
        showToast("error", "Return must come after departure date.");
        return;
      }
      if (
        this.state.returnDate
          .startOf("day")
          .diff(this.state.departDate.startOf("day"), "days") === 0
      ) {
        if (
          moment(this.state.depart_stop1.time, "HH:mm:ss").diff(
            moment(this.state.arrive_stop.time, "HH:mm:ss"),
            "minutes"
          ) < 0
        ) {
          this.setState({
            wrongTime:
              "Arrival time of depart trip must be less than departure time of return trip.",
          });
          showToast(
            "error",
            "Arrival time of depart trip must be less than departure time of return trip."
          );
          return;
        }
      }
    }
    setTimeout(() => this.setState({ currentStep: 3 }), 100);
  }

  checkPastDate(date) {
    return moment().diff(moment(date, "M/D/YY"), "days") > 0;
  }

  checkPastReturnDate(date) {
    return (
      moment(this.state.departDate, "M/D/YY").diff(
        moment(date, "M/D/YY"),
        "days"
      ) > 0
    );
  }

  //checks if the return trip bus depart time is before the depart trip's arrival time
  checkReturnBusTime(returnDate, time) {
    let isReturnAndDepartDateSame =
      moment(this.state.departDate, "M/D/YY").diff(
        moment(returnDate, "M/D/YY"),
        "days"
      ) == 0;
    if (
      isReturnAndDepartDateSame &&
      time &&
      this.state.arrive_stop &&
      this.state.arrive_stop.time
    ) {
      return (
        moment(time, "HH:mm:ss").diff(
          moment(this.state.arrive_stop.time, "HH:mm:ss"),
          "minutes"
        ) < 0
      );
    }

    return false;
  }

  nextWeek(num) {
    if (num === 1) {
      this.createDepartFutureArray(null, "add");
    } else if (num === 2) {
      this.createReturnFutureArray(null, "add");
    }
  }

  previousWeek(num) {
    if (num === 1) {
      this.createDepartFutureArray(null, "subtract");
    } else if (num === 2) {
      this.createReturnFutureArray(null, "subtract");
    }
  }

  hideTrips() {
    this.setState({ isSearchClicked: false });
  }

  render() {
    return (
      <div className="app-container">
        <section id="travel-form-region" className="travel-form">
          {this.props.location.query.reservationNumber && (
            <div className="editing-existing">
              <i className="fa fa-exclamation-circle"></i>Now editing the
              existing reservation{" "}
              <strong>
                #
                {config.formatReservationNumber(
                  this.props.location.query.reservationNumber
                )}
              </strong>
            </div>
          )}
          <div className="container">
            <div className="row mlr-0">
              <div className="card travel-form-wrapper">
                <div className="content content-full-width">
                  {this.state.currentStep === 2 && (
                    <div
                      className={
                        "tab-content" +
                        (this.props.location.query.reservationNumber
                          ? " reduced-shadow"
                          : "") +
                        (this.state.isTripFilterCollapsed === true
                          ? " tab-narrow"
                          : "")
                      }
                    >
                      <div
                        id="round-trip"
                        className="tab-pane fade in active show"
                      >
                        {this.state.isTripFilterCollapsed === false && (
                          <TripFilters
                            data={{
                              departCity: this.state.departCity,
                              departDate: this.state.departDate,
                              arriveCity: this.state.arriveCity,
                              returnDate: this.state.returnDate,
                              returnArriveCity: this.state.returnArriveCity,
                              returnDepartCity: this.state.returnDepartCity,
                              seats: this.state.seats,
                              arriveCities: this.state.arriveCities,
                              returnArriveCities: this.state.returnArriveCities,
                              loading: this.state.loading,
                              isTripFilterCollapsed:
                                this.state.isTripFilterCollapsed,
                            }}
                            setTripFilterCollapsed={this.setTripFilterCollapsed.bind(
                              this
                            )}
                            handleReturnDateChange={this.handleReturnDateChange.bind(
                              this
                            )}
                            handleDepartDateChange={this.handleDepartDateChange.bind(
                              this
                            )}
                            handleUserInput={this.handleUserInput.bind(this)}
                            removeReturn={this.removeReturn.bind(this)}
                            selectTripType={this.selectTripType.bind(this)}
                            isRoundTripSelected={this.state.isRoundTripSelected}
                            getShortLabel={this.getShortLabel.bind(this)}
                            getSchedules={this.getSchedules.bind(this)}
                            hideTrips={this.hideTrips.bind(this)}
                          />
                        )}
                        {this.state.isTripFilterCollapsed === true && (
                          <CollapsedTripFilters
                            data={{
                              departCity: this.state.departCity,
                              departDate: this.state.departDate,
                              arriveCity: this.state.arriveCity,
                              returnDate: this.state.returnDate,
                              returnArriveCity: this.state.returnArriveCity,
                              returnDepartCity: this.state.returnDepartCity,
                              seats: this.state.seats,
                              arriveCities: this.state.arriveCities,
                              returnArriveCities: this.state.returnArriveCities,
                              loading: this.state.loading,
                              isTripFilterCollapsed:
                                this.state.isTripFilterCollapsed,
                              isRoundTripSelected:
                                this.state.isRoundTripSelected,
                            }}
                            setTripFilterCollapsed={this.setTripFilterCollapsed.bind(
                              this
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.currentStep === 2 &&
          this.state.isSearchClicked === true && (
            <Trips
              data={{
                departCity: this.state.departCity,
                departDate: this.state.departDate,
                arriveCity: this.state.arriveCity,
                durationBetweenCities: this.state.durationBetweenCities,
                returnDate: this.state.returnDate,
                returnArriveCity: this.state.returnArriveCity,
                returnDepartCity: this.state.returnDepartCity,
                seats: this.state.seats,
                trips: this.state.trips,
                futureReturns: this.state.futureReturns,
                futureDeparts: this.state.futureDeparts,
                departTrip: this.state.departTrip,
                returnTrip: this.state.returnTrip,
                loading: this.state.loading,
                returnLoading: this.state.returnLoading,
                wrongTime: this.state.wrongTime,
                depart_stop:
                  this.state.depart_stop ||
                  (this.state.departTrip &&
                    this.getRequiredStop(
                      this.state.departTrip.stops,
                      this.state.departCity
                    )),
                depart_time:
                  this.state.depart_time ||
                  (this.state.departTrip &&
                    this.getRequiredStop(
                      this.state.departTrip.stops,
                      this.state.departCity
                    ).time),
                depart_stop1:
                  this.state.depart_stop1 ||
                  (this.state.returnTrip &&
                    this.getRequiredStop(
                      this.state.returnTrip.stops,
                      this.state.returnDepartCity
                    )),
                depart_time1:
                  this.state.depart_time1 ||
                  (this.state.returnTrip &&
                    this.getRequiredStop(
                      this.state.returnTrip.stops,
                      this.state.returnDepartCity
                    ).time),
                arrive_stop:
                  this.state.arrive_stop ||
                  (this.state.departTrip &&
                    this.getRequiredStop(
                      this.state.departTrip.stops,
                      this.state.arriveCity
                    )),
                arrive_stop1:
                  this.state.arrive_stop1 ||
                  (this.state.returnTrip &&
                    this.getRequiredStop(
                      this.state.returnTrip.stops,
                      this.state.returnArriveCity
                    )),
              }}
              isSearchClicked={this.state.isSearchClicked}
              getStopLabel={this.getStopLabel.bind(this)}
              getStopPickup={this.getStopPickup.bind(this)}
              getDropOffStop={this.getDropOffStop.bind(this)}
              getStopMetro={this.getStopMetro.bind(this)}
              checkFutureDepart={this.checkFutureDepart.bind(this)}
              checkActiveDay={this.checkActiveDay.bind(this)}
              getTime={this.getTime.bind(this)}
              selectDepartTrip={this.selectDepartTrip.bind(this)}
              selectReturnTrip={this.selectReturnTrip.bind(this)}
              checkFutureReturns={this.checkFutureReturns.bind(this)}
              getTickets={this.getTickets.bind(this)}
              checkPastDate={this.checkPastDate.bind(this)}
              checkPastReturnDate={this.checkPastReturnDate.bind(this)}
              checkReturnBusTime={this.checkReturnBusTime.bind(this)}
              nextWeek={this.nextWeek.bind(this)}
              previousWeek={this.previousWeek.bind(this)}
              getRequiredStop={this.getRequiredStop.bind(this)}
              getCityCodeFromCityValue={this.getCityCodeFromCityValue.bind(
                this
              )}
              getShortLabel={this.getShortLabel.bind(this)}
              isRoundTripSelected={this.state.isRoundTripSelected}
            />
          )}

        {this.state.currentStep === 3 && (
          <ConfirmTrip
            data={{
              departCity: this.state.departCity,
              departDate: this.state.departDate,
              arriveCity: this.state.arriveCity,
              returnDate: this.state.returnDate,
              returnArriveCity: this.state.returnArriveCity,
              returnDepartCity: this.state.returnDepartCity,
              seats: this.state.seats,
              departTrip: this.state.departTrip,
              returnTrip: this.state.returnTrip,
              depart_stop:
                this.state.depart_stop ||
                (this.state.departTrip &&
                  this.getRequiredStop(
                    this.state.departTrip.stops,
                    this.state.departCity
                  )),
              depart_time:
                this.state.depart_time ||
                (this.state.departTrip &&
                  this.getRequiredStop(
                    this.state.departTrip.stops,
                    this.state.departCity
                  ).time),
              depart_stop1:
                this.state.depart_stop1 ||
                (this.state.returnTrip &&
                  this.getRequiredStop(
                    this.state.returnTrip.stops,
                    this.state.returnDepartCity
                  )),
              depart_time1:
                this.state.depart_time1 ||
                (this.state.returnTrip &&
                  this.getRequiredStop(
                    this.state.returnTrip.stops,
                    this.state.returnDepartCity
                  ).time),
              arrive_stop:
                this.state.arrive_stop ||
                (this.state.departTrip &&
                  this.getRequiredStop(
                    this.state.departTrip.stops,
                    this.state.arriveCity
                  )),
              arrive_stop1:
                this.state.arrive_stop1 ||
                (this.state.returnTrip &&
                  this.getRequiredStop(
                    this.state.returnTrip.stops,
                    this.state.returnArriveCity
                  )),
              reservationNumber: this.props.location.query.reservationNumber,
              busType: this.props.location.query.busType,
              oldSeats: this.props.location.query.seats,
              amount: parseInt(this.props.location.query.amount || 0),
              points: parseInt(this.props.location.query.points || 0),
              oldPrice: parseInt(this.props.location.query.price || 0),
              isRoundTripSelected: this.state.isRoundTripSelected,
            }}
            editTrip={this.editTrip.bind(this)}
            getStopLabel={this.getStopLabel.bind(this)}
            getShortLabel={this.getShortLabel.bind(this)}
            getStopLabelWithZip={this.getStopLabelWithZip.bind(this)}
            getRequiredStop={this.getRequiredStop.bind(this)}
            isEditing={
              this.props.location.query.reservationNumber ? true : false
            }
          />
        )}
        <div className="container route-ticket-details">
          <h1>
            Reserve Your Bus Tickets for Travel Between New York, Bethesda, MD,
            Washington, DC, and Arlington, VA (Rosslyn/Georgetown)!
          </h1>
          <h3>Approximate Travel Times:</h3>
          <ul>
            <li>Bethesda, MD to NYC Bus - 4 hours</li>
            <li>Arlington, VA to NYC Bus - 4.5 hours</li>
            <li>Washington, DC to NYC Bus - 4 hours</li>
            <li>NYC to Bethesda, MD Bus - 4 hours</li>
            <li>NYC to Arlington, VA (Rosslyn/Georgetown) Bus - 4.5 hours</li>
            <li>NYC to Washington, DC Bus - 4 hours</li>
          </ul>
          <h6>
            Please note: We are not responsible for delays due to accidents,
            breakdowns, weather conditions, road issues, or other factors beyond
            our control. Specific arrival or departure times are not guaranteed.
          </h6>
          <h6>
            The prices listed are for individual seats and exclude the Booking
            Fee and Fuel Surcharge applied per transaction.
          </h6>
        </div>
        <section id="name-of-section" className="section-name">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto"></div>
            </div>
          </div>
        </section>
        {/* <div className="scroll-to-top d-lg-none position-fixed ">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div> */}
      </div>
    );
  }
}

export default Home;
