// home.js
import { set } from 'lodash';
import React from 'react';

class Head extends React.Component {


    componentDidMount() {
        if(this.props.title) document.title = this.props.title;

        //get conanical link from head tag and if not present then add it
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('key', 'canonical');
            document.head.appendChild(link);
        }
        link.setAttribute('href', window.location.href);
        //take this.props.children and append it to head tag
        if(this.props.children){
          const head = document.head;
          setTimeout(() => {
            for (let i = 0; i < this.props.children.length; i++) {
              const child = this.props.children[i];
              if (child.type === 'script') {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                if(child.props.children)
                  script.text = child.props.children; // Assuming script content is passed as children
                if (child.props.src)
                  script.src = child.props.src; // If src is passed, script content is ignored

                head.appendChild(script);
              }
            }
          }, 1000);
        }   
      }
    


  render() {
    return null; 
  }
}

export default Head;