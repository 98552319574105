// ReservationStore.js
import Dispatcher from '../dispatcher/Dispatcher';
import { EventEmitter } from 'events';

class ReservationStoreClass extends EventEmitter {

  constructor() {
    super();
    this.reservation = null;
    this.history = null;
    this.upcoming = null;
    Dispatcher.register(this.actionDispatcher.bind(this));
  }

  addReservationChangeListener(callback) {
    this.on('reservation', callback);
  }

  removeReservationChangeListener(callback) {
    this.removeListener('reservation', callback);
  }

  addHistoryChangeListener(callback) {
    this.on('history', callback);
  }

  removeHistoryChangeListener(callback) {
    this.removeListener('history', callback);
  }

  addUpcomingChangeListener(callback) {
    this.on('upcoming', callback);
  }

  removeUpcomingChangeListener(callback) {
    this.removeListener('upcoming', callback);
  }

  getReservation() {
    return this.reservation;
  }

  getHistory() {
    return this.history;
  }

  getUpcoming() {
    return this.upcoming;
  }

  emitReservationChange() {
    this.emit('reservation');
  }

  emitHistoryChange() {
    this.emit('history');
  }

  emitUpcomingChange() {
    this.emit('upcoming');
  }

  actionDispatcher(payload) {
    switch (payload.action.type) {
      case 'SET_RESERVATION':
        this.reservation = payload.action.data;
        this.emitReservationChange();
        break;
      case 'SET_HISTORY':
        this.history = payload.action.data;
        this.emitHistoryChange();
        break;
      case 'SET_UPCOMING':
        this.upcoming = payload.action.data;
        this.emitUpcomingChange();
        break;
      default:
        break;
    }
  }
}
const ReservationStore = new ReservationStoreClass();
export default ReservationStore;