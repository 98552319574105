// ToastrUtils.js

import 'toastr/toastr.scss';
import toastr from 'toastr';

const showToast = (type, message) => {
  toastr.options = {
    closeButton: true,
    timeOut: 3000,
    toastClass: 'toast',
    progressBar: true,
    iconClasses: {
      error: 'error-icon',
      // info: 'toast-info',
      success: 'success-icon',
      // warning: 'toast-warning',
    },

  };

  switch (type) {
    case 'success':
      toastr.success(message);
      break;
    case 'error':
      toastr.error(message);
      break;
    case 'warning':
      toastr.warning(message);
      break;
    case 'info':
      toastr.info(message);
      break;
    default:
      toastr.info(message);
      break;
  }
};

export default showToast;
