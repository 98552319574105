import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

function parseQueryString(queryString) {
  if(queryString === '?=' || !queryString) {
    return {};
  }
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

function addLocationQuery(history){
  history.location = Object.assign(
      history.location,
      {
          query: parseQueryString(history.location.search)
      }
  )
}

addLocationQuery(history);

history.listen(() => {
  addLocationQuery(history);
});