import React from 'react';
import {Link} from 'react-router-dom';
import UserStore from '../../stores/UserStore';
import UserService from '../../services/user';
class Cancelled extends React.Component {
  constructor(props) {
    super(props);
    this.onUserChange = this.onUserChange.bind(this);
    this.state = {
      user: UserStore.getUser()
    }
  }

  onUserChange() {
    this.setState({user: UserStore.getUser()});
  }

  sendResetLink() {
    UserService.sendResetLink(this.props.email)
    .then(() => {
      this.setState({sent: true});
    })
    .catch((err) => {
      this.setState({error: true});
    });
  }

  render() {
    return (
      <div className="container confirmTrip editTrip">
        <div className="editTripBox">
            <div className="header-content text-center">
            <h4><strong>You're all set!</strong></h4>
            {(this.props.refund.credit && this.props.refund.points) ? <p>We have credited your account with ${this.props.refund.credit} and {this.props.refund.points} points towards your next trip.</p> : ''}
            {(this.props.refund.credit && !this.props.refund.points) ? <p>We have credited your account with ${this.props.refund.credit} towards your next trip.</p> : ''}
            {(!this.props.refund.credit && this.props.refund.points) ? <p>We have credited your account with {this.props.refund.points} points towards your next trip.</p> : ''}
            </div>
            {!this.state.user && <div className="note text-center">
              <p><strong>To redeem:</strong></p>
              <p><Link to='/login'>Sign in to your account</Link> to retrieve your credit and purchase new tickets. If you haven't created an account yet, <a href="javascript:void(0)" onClick={this.sendResetLink.bind(this)}>click here to send an email</a> with instructions to claim your account.</p>
              {this.state.sent && <div className="success">Instructions to claim your account have been sent to your email, please click the link there to complete your registration.</div>}
              {this.state.error && <div className="success">Something went wrong. Please call our office at 718-834-9214.</div>}
            </div>}
        </div>
      </div>
    )
  }
}

export default Cancelled;