import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Add missing import
import './checkbox.css';

const Checkbox = ({ label, checked, onChange }) => {

    return (
        <div className="form-check">
            <input className="form-check-input" type="checkbox"  id='sms' checked={checked} onChange={(e)=>{
                onChange(e.target.checked);
            }}/>
            <label className="form-check-label" htmlFor="sms">{label}</label>
        </div>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Checkbox;