import React from 'react';
import UserService from '../../services/user';
import UserStore from '../../stores/UserStore';
import InputMask from 'react-input-mask';
import ReactPasswordStrength from 'react-password-strength';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      user: UserStore.getUser(),
      error: '',
      loading: false,
      success: false,
      password: '',
      confirmPassword: '',
      oldPassword: ''
    }
  }

  handlePassword(result) {
    this.setState({error: '', success: false});
    this.setState({'password_error': ''});
    this.setState({password: result.password});
    if(!result.password) {
      this.setState({'password_error': 'Required'});
    }
    else if(this.state.confirmPassword && result.password !== this.state.confirmPassword) {
      this.setState({'password_error': 'Password and confirm password should match'});
    }
    else if(this.state.confirmPassword && result.password === this.state.confirmPassword) {
      this.setState({'password_error': ''});
    }
  }

  handleInput(e) {
    this.setState({error: '', success: false});
    this.setState({[e.target.name+'_error']: ''});
    this.setState({[e.target.name]: e.target.value});
    if(e.target.required && !e.target.value) {
      let error = {};
      error[e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'confirmPassword' && this.state.password && e.target.value !== this.state.password) {
      let error = {};
      error[e.target.name+'_error'] = 'Password and confirm password should match';
      this.setState(error);
    }
    else if(e.target.name === 'confirmPassword' && e.target.value && this.state.password && e.target.value === this.state.password) {
      let error = {};
      error['password_error'] = '';
      this.setState(error);
    }
  }

  validateData() {
    let obj = {};
    if(!this.state.oldPassword) {
      obj['oldPassword_error'] = 'Required';
    }
    if(!this.state.password) {
      obj['password_error'] = 'Required';
    }
    if(!this.state.confirmPassword) {
      obj['confirmPassword_error'] = 'Required';
    }
    if(this.state.password && this.state.confirmPassword && this.state.password !== this.state.confirmPassword) {
      obj['confirmPassword_error'] = 'Password and confirm password should match';
    }
    if(this.state.password && this.state.password === this.state.oldPassword) {
      obj['password_error'] = 'New password can not be the same as old password';
    }
    if (Object.keys(obj).length > 0) {
      this.setState(obj);
      return false;
    }
    else {
      return true;
    }
  }

  updateDetails(e) {
    e.preventDefault();
    if (!this.validateData()) {
      return;
    }
    this.setState({loading: true, error: '', success: false});
    let user = {
      password: this.state.password,
      oldPassword: this.state.oldPassword
    };
    UserService.update(this.state.user.id, user)
    .then(() => {
      this.setState({loading: false, success: true})
    })
    .catch((err) => {
      this.setState({loading: false, error: (err.response.text || err.response.statusText)});
    });
  }

  onChange() {
    
    this.setState({user: UserStore.getUser()});
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
        <div className="overview">
            <div className="customerInformation">
                <div className="lSecHeader step-a">
                    <h4 className="heading4">Change Your Password</h4>
                    <p>Enter your current password and then select a new password.</p>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-12 form-group">
                    <label className="formLable">CURRENT PASSWORD</label>
                    <input required type="password" ref="oldPassword" name="oldPassword" value={this.state.oldPassword} onChange={this.handleInput.bind(this)} className="form-control username" placeholder="" />
                    <div className="error">{this.state.oldPassword_error}</div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-12 form-group">
                    <label className="formLable">NEW PASSWORD</label>
                    <ReactPasswordStrength
                      ref='password'
                      minLength={6}
                      changeCallback={this.handlePassword.bind(this)}
                      inputProps={{ name: 'password', autoComplete: 'off', className: 'form-control username', required: 'true' }}
                    />
                    <div className="error">{this.state.password_error}</div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-12 form-group">
                    <label className="formLable">CONFIRM PASSWORD</label>
                    <input  ref="confirmPassword" type="password" required name="confirmPassword" onChange={this.handleInput.bind(this)} value={this.state.confirmPassword} type="password" className="form-control username" />
                    <div className="error">{this.state.confirmPassword_error}</div>
                  </div>
                </div>
                <div className="row form-group clearfix">
                  <div className="col-sm-12">
                    <button type="button" disabled={this.state.loading} onClick={this.updateDetails.bind(this)} className="btn btn-blue">SAVE</button>
                  </div>
                </div>
                {this.state.error && <div className="error server-error">Error: {this.state.error}</div>}
                {this.state.success && <div className="success server-success">Password Changed Successfully.</div>}
            </div>
        </div>
    )
  }
}

export default ChangePassword;