export const eventTracker = (category, action, label) => {
  window.gtag("event", "click", {
    action: action,
    label: label,
    category: category,
  });
};

export const purchaseEvent = (bookedTickets) => {
  console.log("bookedTickets", bookedTickets)
  window.gtag("event", "purchase", bookedTickets);
};