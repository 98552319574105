// auth_service.js
import request from 'superagent/lib/client';
import { loginUser, logoutUser } from '../actions/UserActions';
import config from '../config';

class AuthService {
  login(email, password) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'authenticate')
      .send({email: email, password: password})
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(loginUser(response.body.token));
        }
        else if(error){
          reject('Email or password is invalid');
        }
        else{
          reject('Email or password is invalid');
        }
      });
    });
  }

  checkAuth() {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+'check-auth')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (error || response.statusCode === 401) {
          reject(logoutUser());
        }
        else {
          resolve('success');
        }
      });
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'forgot')
      .send({email: email})
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve('Success');
        }
        else if(error){
          reject(error);
        }
        else{
          reject('Something went wrong');
        }
      });
    });
  }

  resetPassword(token, password) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'reset/'+token)
      .send({password: password})
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve('Success');
        }
        else if(error){
          reject(error);
        }
        else{
          reject('Something went wrong');
        }
      });
    });
  }

  verifyCaptcha(token) {
    return new Promise((resolve, reject) => {
      request.get(config.apiUrl+`verify/${token}`)
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (error || response.statusCode === 401) {
          reject('Captcha Invalid');
        }
        else {
          resolve(response.body);
        }
      });
    });
  }

  logout() {
    logoutUser();
  }
}

export default new AuthService();
