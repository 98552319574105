import React from "react";
class Modal extends React.Component {

  render() {
    return (
        <div
          className="modal fade"
          id={this.props.id? this.props.id: "myModal"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div
               className="modal-dialog"
               style={{top: '20%'}}
               role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Modal;
