import React from 'react';
import ReservationService from '../../services/reservation';
import ReservationStore from '../../stores/ReservationStore';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config';
import { history as browserHistory } from '../../helpers/history';
import eliteIcon from '../../img/elite svg.svg';
import numeral from 'numeral';

class ReservationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.onHistoryChange = this.onHistoryChange.bind(this);
    this.state = {
      reservations: [],
      loading: false,
      error: '',
      downloading: null,
      resending: null,
      cities: config.cities
    };
  }

  getStopLabel(stop) {
    return _.find(this.state.cities, {value: stop}).label;
  }

  getShortStopLabel(stop) {
    return _.find(this.state.cities, {value: stop}).shortLabel;
  }

  getStopMetro(stop) {
    return _.find(this.state.cities, {value: stop}).metro;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, {value: stop}).address;
  }

  getTime(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  onHistoryChange(){
    console.log(ReservationStore.getHistory());
    let result = ReservationStore.getHistory();
    if(result) {
      result = _.orderBy(result, (item) => {
        return -(moment(item.slot + ' ' + item.depart_stop.time, 'YYYY-MM-DD HH:mm:ss'));
      });
      this.setState({reservations: result});
    }
  }

  getReservationHistory() {
    this.setState({loading: true, error: ''});
    ReservationService.getReservationHistory()
    .then(() => {
      this.setState({loading: false})
    })
    .catch((err) => {
      this.setState({loading: false, error: err.response.statusText});
    });
  }

  componentDidMount() {
    ReservationStore.addHistoryChangeListener(this.onHistoryChange);
    this.getReservationHistory();
  }

  componentWillUnmount() {
    ReservationStore.removeHistoryChangeListener(this.onHistoryChange);
  }

  checkFuture(slot) {
    return (new Date(slot).getTime() > new Date().getTime())
  }

  mailTicket(number) {
    this.setState({loading: true, resending: number, downloading: null});
    ReservationService.mailTicket(number)
    .then(() => {
      this.setState({loading: false, resending: null})
    })
    .catch((err) => {
      this.setState({loading: false, error: err.response.statusText, resending: null});
    });
  }

  createBlob(data, filename){
    var blob = new Blob([data], {type: 'application/pdf'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  download(code) {
    this.setState({loading: true, downloading: code, resending: null});
    ReservationService.download(code)
    .then((result) => {
      this.createBlob(result.body, 'ticket.pdf');
      this.setState({loading: false, downloading: null});
    })
    .catch((err) => {
      this.setState({loading: false, error: err.toString(), downloading: null});
    });
  }

  editSeats(number) {
    browserHistory.push('/edit-trip?reservationNumber=' + number);
  }

  render() {
  	return (
  		<div className="overview reservation-history">
          <div className="heading">Reservation History</div>
          <div className="history">
            {this.state.reservations && this.state.reservations.map((item, index) => {
              return (
                <div className={"card status-" + item.status} key={index}>
                  <div className="card-header">
                    <div className={"status " + item.status}>{item.status}</div>
                    <div className="res-number"><div className="only-desktop reservation-label">RESERVATION</div># <span>{config.formatReservationNumber(item.reservationNumber)}</span>
                      {item.type === 'Elite' ? <div className="elite-bus-icon"><img height="15px" src={eliteIcon} /></div> : null}
                    </div>
                    {item.status === 'active' && <div>
                      <div className="dropdown">
                        <a href="javascript:void(0)" className="dropdown-toggle options">Options <b className="caret"></b></a>
                        <ul className="dropdown-menu">
                          <li><a className={(this.state.resending === item.reservationNumber ? 'active': '')} href="javascript:void(0)" onClick={this.mailTicket.bind(this, item.reservationNumber)}>Re-send Email Confirmation</a></li>
                          <li><a className={(this.state.downloading === item.reservationNumber ? 'active': '')} href="javascript:void(0)" onClick={this.download.bind(this, item.reservationNumber)}>Print Ticket</a></li>
                          <li><a href="javascript:void(0)" onClick={this.editSeats.bind(this, item.reservationNumber)}>Reschedule Seat(s)</a></li>
                          <li><a href="javascript:void(0)" onClick={this.editSeats.bind(this, item.reservationNumber)}>Cancel Seat(s)</a></li>
                        </ul>
                      </div>
                    </div>}
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-2">
                              <div className="sub-fields">DATE/TIME</div>
                              <div className="sub-fields-value mb-2">{moment(item.slot, 'YYYY-MM-DD').format('MM/DD/YY')} {moment(item.depart_stop.time, 'HH:mm:ss').format('hh:mm a')}</div>
                            </div>
                            <div className="sub-fields">PAYMENT METHOD</div>
                            <div className="sub-fields-value">{item.paymentMethod}</div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-2">
                              <div className="sub-fields">ROUTE</div>
                              <div className="sub-fields-value">{this.getShortStopLabel(item.depart_stop.city)} to {this.getShortStopLabel(item.arrive_stop.city)}</div>
                            </div>
                            <div className="sub-fields">LAST MODIFIED</div>
                            <div className="sub-fields-value">{moment(item.updated_at).format('M/DD/YY')}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="sub-fields">SEATS</div>
                            <div className="sub-fields-value">{item.seats}</div>
                          </div>
                          <div className="col-sm-8">
                            <div className="sub-fields">ORDER SUMMARY</div>
                            <div className="order-summary-data">
                            <div className="sub-fields-row"><span>Ticket value:</span> <span className="sub-fields-val ticketValue">{numeral(item.ticketValue).format('$0,0[.]00')}</span></div>
                            {item.pointsUsed > 0 && <div className="sub-fields-row"><span>Total points used:</span> <span className="sub-fields-val points-used">{item.pointsUsed}</span></div>}
                            {item.creditsUsed > 0 && <div className="sub-fields-row"><span>Total credit used:</span> <span className="sub-fields-val credit-used">{item.creditsUsed}</span></div>}
                            <div className="sub-fields-row"><span>Total amount paid:</span> <span className="sub-fields-val totalPaid">{numeral(item.totalPaid || 0).format('$0,0[.]00')}</span></div>
                            {item.pointsEarned > 0 && <div className="sub-fields-row"><span>Total points earned:</span> <span className="sub-fields-val creditsEarned">+{item.pointsEarned}</span></div>}
                            {item.creditsEarned > 0 && <div className="sub-fields-row"><span>Total credit to account:</span> <span className="sub-fields-val creditsEarned">+{item.creditsEarned}</span></div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {this.state.loading &&
              <div className="fond">
                <div className="contener_general">
                  <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
                  <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
                </div>
              </div>
            }
          </div>
        </div>
  	)
  }
}

export default ReservationHistory;

