import React from 'react';
import Auth from '../../services/auth';

class ResetPassword extends React.Component{
  constructor() {
    super()
    this.state = {
      email: '',
      status: '',
      loading: false
    };
  }

  componentDidMount(){
    window.$('#myModal').on('hidden.bs.modal', (e)=>{
        this.setState({      email: '',
        status: '',
        loading: false})
    })
  }


  forgotPassword(e) {
    e.preventDefault();
    this.setState({status: ''});
    if(!this.showFormErrors()){
        return;
    }
    this.setState({loading: true});
    Auth.forgotPassword(this.state.email)
    .then(() => {
      this.setState({status: 'success', email: '', loading: false})
    })
    .catch((err) => {
      this.setState({loading: false});
      if(err && err.response && err.response.text) {
        this.setState(function(){
          return {status: err.response.text};
        });
      }
      else {
        this.setState(function(){
          return {status: err};
        });
      }
    });
  }

  showFormErrors() {
    const inputs = document.querySelectorAll('#resetEmail');
    let isFormValid = true;
    inputs.forEach(input => {
      const isInputValid = this.showInputError(input.name);
      if (!isInputValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  }

  showInputError(refName) {
    
    const validity = this.refs[refName].validity;
    const label = document.getElementById(`${refName}Label`).textContent;
    const error = document.getElementById(`${refName}Error`);
    if (!validity.valid) {
      if (validity.valueMissing) {
        error.textContent = `${label} is a required field`;
      } else if (validity.typeMismatch) {
        error.textContent = `${label} should be a valid email address`;
      }
      return false;
    }

    error.textContent = '';
    return true;
  }

  handleUserInput(e){
    this.setState({status: ''});
    this.setState({[e.target.name]: e.target.value});
    this.showInputError(e.target.name);
  }


  render(){
    return (
      <div className="col-sm-12 loginForm forgotForm">
                <form name="loginForm" noValidate>

                  <div>
                    <h4>Help Recover Your Account</h4>
                    {this.state.status !== 'success' && <div> <p>Enter your email address below and we will send you an email to reset your password.</p>
                    <div className="form-group relative-form-group">
                      <label htmlFor="inputState" id="emailLabel" className="formLable">Email Address</label>
                      <input type="email" id="resetEmail" onChange={this.handleUserInput.bind(this)} value={this.state.email} name="email" ref="email" required className="form-control" placeholder="" />
                      <div className="error" id="emailError" />
                    </div>
                    <div className="form-group">
                      <div disabled={this.state.loading} className="btn btn-blue" onClick={this.forgotPassword.bind(this)}>Reset Password</div>
                    </div>
                  </div>}
                  </div>
                  <div className="form-group">
                    {this.state.status && (this.state.status != 'success' ? <div>
                      <div className="error server-error">{this.state.status}</div>
                    </div> : <div>
                        <div className="status server-success">Password reset instructions have been sent to your email. Please check your spam folder and wait at least 10 minutes before requesting another reset email.</div>
                      </div>)}
                  </div>
                </form>
              </div>
    )
  }

}

export default ResetPassword;
