// application.js
import request from 'superagent';
import config from '../config';

class OrderService {

  getUtm() {
    try{
      const cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].split('=');
      if(cookie[0].trim()==='utm'){
        var obj = JSON.parse(decodeURIComponent(cookie[1]))
        return {utm: obj?.utm, utm_campaign: obj?.utm_campaign}
      }
    }
    const queryParamsString = window.location.search;
    const queryParams = new URLSearchParams(queryParamsString);
    var utm = ''
    var utm_campaign = queryParams.get('utm_campaign')
    if(utm_campaign===null){
      utm_campaign = ''
    }
    queryParams.entries().forEach((entry) => {
      if(entry[0].startsWith('utm_')){
        if(utm===''){
          utm = entry[1]
        }
        else{
          utm=utm+"_"+entry[1]
        }
      }
    }
    )

    return {utm, utm_campaign}
    }catch(error){
      console.log(`[UTM] Error parsing UTM`, error)
      return {utm:'', utm_campaign:''}
    }
  }

  deleteCookie() {
    document.cookie = "utm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  registerAndCreateOrder(body) {

    var utm = this.getUtm()
    if(utm.utm!==''){
      body.utm = utm.utm
    }
    if(utm.utm_campaign!==''){
      body.utm_campaign = utm.utm_campaign
    }

    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'orders/profile/new')
      .send(body)
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          this.deleteCookie()
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  createOrder(body) {
    var utm = this.getUtm()
    if(utm.utm!==''){
      body.utm = utm.utm
    }
    if(utm.utm_campaign!==''){
      body.utm_campaign = utm.utm_campaign
    }
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'orders/charge')
      .send(body)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          this.deleteCookie()
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  createGift(body) {
    return new Promise((resolve, reject) => {
      request.post(config.apiUrl+'gift_cards')
      .send(body)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  reschedule(body) {
    if(localStorage.getItem('token')) {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'orders/reschedule-with-token')
        .send(body)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer '+localStorage.getItem('token'))
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            resolve(response.body);
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    }
    else {
      return new Promise((resolve, reject) => {
        request.post(config.apiUrl+'orders/reschedule')
        .send(body)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
            resolve(response.body);
          }
          else if(error){
            reject(error);
          }
          else{
            reject(response.body);
          }
        });
      })
    } 
  }

  verifyCoupon(userId, code, date, isElite) {
    return new Promise((resolve, reject) => {
      let url = config.apiUrl+'coupons/verify/' + code + '/' + date;
      if(userId && isElite) {
        url = url + '?user=' + userId + '&elite=true';
      }
      else if(userId) {
        url = url + '?user=' + userId;
      }
      else if(isElite) {
        url = url + '?elite=true';
      }
      request.get(url)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }

  verifyGiftCard(code) {
    return new Promise((resolve, reject) => {
      let url = config.apiUrl+'gift_cards/verify/' + code;
      request.get(url)
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer '+localStorage.getItem('token'))
      .end((error, response) => {
        if (!error && response.statusCode >= 200 && response.statusCode <= 304) {
          resolve(response.body);
        }
        else if(error){
          reject(error);
        }
        else{
          reject(response.body);
        }
      });
    })
  }
}

export default new OrderService();